/* eslint-disable import/no-cycle */
import { useEffect, useMemo, useCallback } from 'react';
// hooks
import { useLocalStorage, getStorage } from 'src/hooks/use-local-storage';
// routes
// import { paths } from 'src/routes/paths';
// // _mock
// import { PRODUCT_CHECKOUT_STEPS } from 'src/_mock/_product';
// types
import { IItem } from 'src/types/Item';
//
import { CheckoutContextProps } from 'src/types/checkout';
import { useTable } from 'src/hooks/use-table';
import { createOrder } from 'src/apis/checkout'
import { useUuid } from 'src/auth/hooks';
// import { ICoupon } from 'src/types/Coupon';
import { CheckoutContext } from './checkout-context';

// ----------------------------------------------------------------------

const STORAGE_KEY = 'checkout';

const initialState = {
  items: [],
  coupon: '',
  couponData: null,
  shipping: false,
  shipPrice: 0,
  price: 0,
  totalItems: 0,
  customer: {
    name: 'Khách lẻ',
    phone: '',
    email: '',
  },
  preparedItems: [],
  newOrder: false,
  notes: {},
};

type Props = {
  children: React.ReactNode;
};


export function CheckoutProvider({ children }: Props) {
  const { state, update, reset, updateState } = useLocalStorage(STORAGE_KEY, initialState);
  const { restoreTable: restore, getTable } = useTable()
  const { customer } = useUuid()

  const onGetCart = useCallback(
    () => {
      if (!getTable()) {
        reset()
        return
      }

      const totalItems: number = state.items.reduce(
        (total: number, item: IItem) => total + item.quantity,
        0
      );

      const totalPrice: number = state.items.reduce(
        (total: number, item: IItem) => total + item.quantity * (item.price + item.options.reduce(
          (oTotal: number, option) => oTotal + option.choices.reduce(
            (cTotal: number, choice) => cTotal + (choice.price * choice.quantity),
            0,
          ),
          0,
        )),
        0,
      )

      updateState({
        // promotion: {},
        // shipping: false,
        // shipPrice: 0,
        price: totalPrice,
        totalItems,
        // customer: state.customer,
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.items, getTable],
  );

  useEffect(() => {
    const restored = getStorage(STORAGE_KEY);

    if (restored) {
      onGetCart();
    }
  }, [onGetCart]);

  const onAddToCart = useCallback(
    (newItem: IItem) => {
      const updatedItems: IItem[] = [...state.items]
      updatedItems.push(newItem);

      update('items', updatedItems);
    },
    [update, state.items]
  );

  const onDeleteCart = useCallback(
    (index: number) => {
      const updatedItems = state.items.toSpliced(index, 1)

      update('items', updatedItems);
    },
    [state.items, update]
  );

  const onDeleteMany = useCallback(
    (indexs: number[]) => {
      const updatedItems: IItem[] = state.items.reduce(
        (arr: IItem[], item: IItem, index: number) => {
          if (!indexs.includes(index)) arr.push(item)
          return arr
        },
        []
      )

      update('items', updatedItems);
    },
    [state.items, update]
  )

  const onUpdateItem = useCallback(
    (index: number, item: IItem) => {
      if (!state.items[index]) {
        onAddToCart(item)
        return
      }

      const updatedItems = [...state.items]
      updatedItems[index] = item

      update('items', updatedItems);
    },
    [update, onAddToCart, state.items]
  );

  const onCreateOrder = useCallback(
    (paymentMethod: string, coupon?: string) => {
      const tableData = getTable()

      if (!state.items.length) return Promise.reject(Error('items is empty'))
      if (!tableData) return Promise.reject(Error('not found table'))

      return createOrder({
        items: state.items,
        table: tableData,
        notes: state.notes,
        customer,
        paymentMethod,
        coupon: coupon || '',
      }).then((data) => {
        reset()
        return data.data
      }).catch((error) => Promise.reject(error))
    },
    [customer, getTable, reset, state.items, state.notes]
  );

  // Reset
  const onReset = useCallback(() => {
    reset();
    // router.replace(paths.product.root);
    // router.replace(`/`)
  }, [reset]);

  const restoreTable = useCallback(
    () => {
      restore()
    },
    [restore],
  )

  const setNewOrder = useCallback(
    (value: boolean = false) => {
      update('newOrder', value)
    },
    [update],
  )

  const getNotes = useCallback(
    () => state.notes,
    [state.notes],
  )

  const setNote = useCallback(
    (sId: string, note: string) => {
      const updateNotes = { ...state.notes }
      updateNotes[sId] = note
      update('notes', updateNotes)
    },
    [state.notes, update],
  )

  // const applyCoupon = useCallback(
  //   (coupon: string) => {
  //     if (!coupon) update('coupon', '')
  //     else update('coupon', coupon)
  //   },
  //   [update],
  // )

  // const setCouponData = useCallback(
  //   (couponData?: ICoupon | null) => {
  //     update('couponData', couponData || null)
  //   },
  //   [update],
  // )

  const memoizedValue = useMemo<CheckoutContextProps>(
    () => ({
      ...state,
      //
      onAddToCart,
      onDeleteCart,
      onDeleteMany,
      onUpdateItem,
      //
      onCreateOrder,
      //
      onReset,
      restoreTable,
      setNewOrder,
      getNotes,
      setNote,
    }),
    [
      onAddToCart,
      onCreateOrder,
      onUpdateItem,
      onDeleteCart,
      onDeleteMany,
      onReset,
      restoreTable,
      setNewOrder,
      state,
      getNotes,
      setNote,
    ]
  );

  return <CheckoutContext.Provider value={memoizedValue}>{children}</CheckoutContext.Provider>;
}
