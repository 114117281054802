export const className = {
  
}

export default {
  MAX_WIDTH: '540px',
  CONTAINER_PADDING: '12px',
  PADDING_XL: '26px',
  BG_COLOR: '#F5F5F5',
  BORDER: '1px solid rgba(0, 0, 0, 0.10)',
  BORDER_PRIM: '1px solid #39B54A',
  COLOR_DANGER: '#F05123',
  COLOR_BASE: '#212B36',
  COLOR_APP_PRIM: '#39B54A',
  COLOR_LINE_GREY: '#85898d',
  COLOR_WARNING: '#FEFF58',
  BG_PRIM: 'rgba(57, 181, 74, 0.2)'
  // COLOR_LINE_GREY: '#E9EBED',
}
