// @mui
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Badge, { badgeClasses } from '@mui/material/Badge';
// hooks
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
// theme
import { bgBlur } from 'src/theme/css';
// routes
import { paths } from 'src/routes/paths';
// components
import BackArrowIcon from 'src/assets/icons/back-arrow.svg'
// import Label from 'src/components/label';
// //
// import { HEADER } from '../config-layout';
// import { navConfig } from './config-navigation';
// import NavMobile from './nav/mobile';
// import NavDesktop from './nav/desktop';
//
// import { SettingsButton, HeaderShadow, LoginButton } from '../_common';
import style from 'src/constants/style'
import { CustomerSimple } from 'src/components/customer'
import { useRouter } from 'src/routes/hooks';

// ----------------------------------------------------------------------

type Props = {
  title: string,
  showCustomerInfo?: boolean,
}

export default function Header({ title, showCustomerInfo = false }: Props) {
  const theme = useTheme();
  const router = useRouter()

  return (
    <>
      <AppBar sx={{
        maxWidth: style.MAX_WIDTH,
        left: '50%',
        transform: 'translateX(-50%)',
        background: 'white',
        borderBottom: style.BORDER,
      }}>
        <Toolbar
          disableGutters
          sx={{
            transition: theme.transitions.create(['height'], {
              easing: theme.transitions.easing.easeInOut,
              duration: theme.transitions.duration.shorter,
            })
          }}
        >
          <Container sx={{
            height: 1,
            display: 'flex',
            alignItems: 'center',
            paddingLeft: style.CONTAINER_PADDING,
            paddingRight: style.CONTAINER_PADDING,
          }}>
            <Box
              sx={{
                width: 1,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={() => router.back()}
            >
              <Box sx={{
                flex: 1,
                flexShrink: 0,
                width: 48,
                height: 48,
                background: `url('${BackArrowIcon}')`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'left center',
              }} />

              <Box sx={{ flexGrow: 1, textAlign: 'center', fontWeight: 600, }}>
                {title}
              </Box>

              <Box sx={{ flex: 1 }} />
            </Box>
            
          </Container>
        </Toolbar>

      </AppBar>

      {showCustomerInfo && <Box
        sx={{
          width: 1,
          minHeight: 40,
          position: 'fixed',
          top: '56px',
          maxWidth: style.MAX_WIDTH,
          paddingLeft: style.CONTAINER_PADDING,
          paddingRight: style.CONTAINER_PADDING,
          background: 'white',
          borderBottom: style.BORDER,
          textAlign: 'center',
          paddingTop: 1,
          paddingBottom: 1,
          zIndex: 1000,
        }}
      >
        <CustomerSimple />
      </Box>}
    </>
  );
}
