import { Box, Drawer, SxProps, Theme } from "@mui/material"
import { ReactNode } from "react"
import style from "src/constants/style";

type Props = {
  open?: boolean;
  children: ReactNode;
  onClose?: () => any;
  sx?: SxProps<Theme>;
  contentSx?: SxProps<Theme>;
}

export default function AirDrawer ({ open, children, onClose, sx = {}, contentSx = {} }: Props) {
  return (
    <Drawer
      anchor='bottom'
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          margin: 'auto',
          maxHeight: 0.9,
          maxWidth: style.MAX_WIDTH,
          background: '#0ff',
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
          display: 'flex',
          flexDirection: 'column',
        }
      }}
      sx={sx}
    >
      <Box
        display='flex'
        justifyContent='center'
        marginBottom={3}
        onClick={onClose}
      >
        <Box sx={{
          background: style.COLOR_LINE_GREY,
          width: 48,
          height: 6,
          borderRadius: 3,
          marginTop: 1,
        }} />
      </Box>

      <Box sx={{
        flexGrow: 1,
        overflow: 'scroll',
        ...contentSx,
      }}>
        {children}
      </Box>
    </Drawer>
  )
}
