import { lazy, Suspense } from 'react';
// layouts
import DetailLayout from 'src/layouts/detail';
// components
import { SplashScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

const Checkout = lazy(() => import('src/pages/checkout/checkout'));
const Payment = lazy(() => import('src/pages/checkout/payment'));

// ----------------------------------------------------------------------

export const checkoutRoutes = [
  {
    path: '/checkout',
    element: (
      <DetailLayout title='Xác nhận đơn hàng' showCustomerInfo>
        <Suspense fallback={<SplashScreen />}>
          <Checkout />
        </Suspense>
      </DetailLayout>
    ),
  },
  {
    path: '/payment/:id',
    element: (
      <DetailLayout title='Quét mã QR để thanh toán'>
        <Suspense fallback={<SplashScreen />}>
          <Payment />
        </Suspense>
      </DetailLayout>
    ),
  },
];
