// @mui
import Box from '@mui/material/Box';
// routes
import { usePathname } from 'src/routes/hooks';
//
// import Footer from './footer';
import style from 'src/constants/style'
import Container from '@mui/material/Container';
import Header from './header';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
  title: string;
  showCustomerInfo?: boolean;
  noPadding?: boolean;
};

export default function DetailLayout({ children, title, showCustomerInfo = false, noPadding = false }: Props) {
  const pathname = usePathname();


  return (
    <Box 
      maxWidth={style.MAX_WIDTH}
      margin="auto"
      display='flex'
      flexDirection='column'
      height={1}
      sx={{ background: style.BG_COLOR, fontSize: '14px', fontFamily: 'Lexend' }}
    >
      <Header title={title} showCustomerInfo={showCustomerInfo} />

      <Box
        component="main"
        sx={{
          marginTop: showCustomerInfo ? 12 : 7,
          pt: noPadding ? 0 : 2,
          flexGrow: 1,
          background: style.BG_COLOR,
        }}
      >
        <Container sx={{ height: 1, padding: 0 }}>
          {children}
        </Container>
      </Box>

      {/* <Footer /> */}
    </Box>
  );
}
