import * as React from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import style from 'src/constants/style';

import ReceiptItemIcon from 'src/assets/icons/receipt-item.svg'
import ReceiptAddIcon from 'src/assets/icons/receipt-add.svg'
import MoreIcon from 'src/assets/icons/more.svg'
import ReceiptItemActiveIcon from 'src/assets/icons/receipt-item-active.svg'
import ReceiptAddActiveIcon from 'src/assets/icons/receipt-add-active.svg'
import MoreActiveIcon from 'src/assets/icons/more-active.svg'
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
import { styled } from '@mui/material';

function samePageLinkNavigation(
  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
) {
  if (
    event.defaultPrevented ||
    event.button !== 0 || // ignore everything but left-click
    event.metaKey ||
    event.ctrlKey ||
    event.altKey ||
    event.shiftKey
  ) {
    return false;
  }
  return true;
}

type StyledTabProps = {
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => any;
  label?: string;
  href?: string;
  icon?: React.ReactElement;
  active?: boolean
}

const StyledTab = styled((props: StyledTabProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { active } = props
  const tabProps = { ...props }
  delete tabProps.active

  return (
  <Tab 
    component="a"
    disableRipple
    iconPosition='top'
    sx={{
      '.Mui-selected': {
        color: style.COLOR_APP_PRIM ,
      },
    }}

    {...tabProps}
  />
)})(({ theme, active }) => ({
  textTransform: 'none',
  fontFamily: 'Lexend',
  fontWeight: 400,
  // color: 'rgba(255, 255, 255, 1)',
  
  color: style.COLOR_APP_PRIM,
  '&:not(.Mui-selected)': {
    color: active ? style.COLOR_APP_PRIM : 'black',
  },
  '&.Mui-selected': {
    color: style.COLOR_APP_PRIM ,
  },
}));

interface LinkTabProps {
  label?: string;
  href?: string;
  icon?: React.ReactElement;
  active?: boolean;
}

function LinkTab(props: LinkTabProps) {
  const router = useRouter()
  const { href } = props

  return (
    <StyledTab
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        // Routing libraries handle this, you can remove the onClick handle when using them.
        if (samePageLinkNavigation(event)) {
          event.preventDefault();
          router.push(href || '/')
        }
      }}
      {...props}
    />
  );
}

type NavIconProps = {
  icon: string;
  text: string;
  destination: string;
  active?: boolean;
}

function NavItem ({ icon, text, destination, active = false }: NavIconProps) {
  return (
    <LinkTab
      label={text}
      href={destination}
      icon={<img src={icon} alt={text} />}
      active={active}
    />
  )
}

type Props = {
  activeTab?: number;
}

export default function Nav ({ activeTab = 0 }: Props) {
  return (
    <Box sx={{
      position: 'fixed',
      bottom: 0,
      // display: 'grid',
      // gridTemplateColumns: '33.333% 33.333% 33.333%',
      paddingTop: style.CONTAINER_PADDING,
      paddingBottom: 2,
      paddingLeft: style.CONTAINER_PADDING,
      paddingRight: style.CONTAINER_PADDING,
      background: 'white',
      width: 1,
      maxWidth: style.MAX_WIDTH,
      borderTop: style.BORDER,
    }}>
      <Tabs
        value={activeTab}
        aria-label="nav"
        scrollButtons={false}
        onChange={(e: any, newValue) => console.log('qweqweqwe', newValue)}
        sx={{
          // width: 1,
          overflow: 'visible',
          '.MuiTabs-flexContainer': {
            display: 'grid',
            // gridTemplateColumns: '33.333% 33.333% 33.333%',
            gridTemplateColumns: '50% 50%',
          },
          '.MuiTabs-scroller': {
            overflow: 'visible',
          },
        }}
        TabIndicatorProps={{
          sx: {
            height: 2,
            background: style.COLOR_APP_PRIM,
            top: -13,
          }
        }}
      >
        <NavItem
          icon={activeTab === 0 ? ReceiptItemActiveIcon : ReceiptItemIcon}
          text='Đơn hàng'
          destination={paths.order.order}
          active={activeTab === 0}
        />

        <NavItem
          icon={activeTab === 1 ? ReceiptAddActiveIcon : ReceiptAddIcon}
          text='Gọi món'
          destination={paths.table.menu}
          active={activeTab === 1}
        />

        {/* <NavItem
          icon={activeTab === 2 ? MoreActiveIcon : MoreIcon}
          text='Thêm'
          destination={paths.empty.more}
          active={activeTab === 2}
        /> */}
      </Tabs>
    </Box>
  )
}
