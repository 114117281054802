import { Navigate, useRoutes } from 'react-router-dom';
// layouts
// import MainLayout from 'src/layouts/main';
// config
// import { PATH_AFTER_LOGIN } from 'src/config-global';
//
import { mainRoutes } from './main';
import { emptyRoutes } from './empty'
// import { HomePage } from './main'
import { tableRoutes } from './table'
import { checkoutRoutes } from './checkout'
import { orderRoutes } from './order'

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // SET INDEX PAGE WITH SKIP HOME PAGE
    // {
    //   path: '/',
    //   element: <Navigate to={PATH_AFTER_LOGIN} replace />,
    // },

    // ----------------------------------------------------------------------


    // SET INDEX PAGE WITH HOME PAGE
    // {
    //   path: '/',
    //   element: (
    //     <MainLayout>
    //       <HomePage />
    //     </MainLayout>
    //   ),
    // },
    ...tableRoutes,
    ...checkoutRoutes,
    ...orderRoutes,
    ...emptyRoutes,
    ...mainRoutes, // 404, 403, 500


    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
