import { lazy, Suspense } from 'react';
// layouts
import DetailLayout from 'src/layouts/detail';
import MainLayout from 'src/layouts/main';
// components
import { SplashScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

const Bill = lazy(() => import('src/pages/order/bill'));
const Detail = lazy(() => import('src/pages/order/detail'));
const Order = lazy(() => import('src/pages/order/order'));

// ----------------------------------------------------------------------

export const orderRoutes = [
  {
    path: '/bill/:id',
    element: (
      <DetailLayout title='Chi tiết hóa đơn' noPadding>
        <Suspense fallback={<SplashScreen />}>
          <Bill />
        </Suspense>
      </DetailLayout>
    ),
  },
  {
    path: '/order/:id',
    element: (
      <DetailLayout title='Chi tiết đơn hàng' noPadding>
        <Suspense fallback={<SplashScreen />}>
          <Detail />
        </Suspense>
      </DetailLayout>
    ),
  },
  
  {
    path: '/order',
    element: (
      <MainLayout noPadding activeTab={0}>
        <Suspense fallback={<SplashScreen />}>
          <Order />
        </Suspense>
      </MainLayout>
    ),
  },
];
