import axios, { AxiosRequestConfig,  } from 'axios';
// config
import { HOST_API } from 'src/config-global';


// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data?.error) || 'Something went wrong')
);

const locInstance = axios.create({ baseURL: 'http://localhost:3000' });

locInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data?.error) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args: string | [string, AxiosRequestConfig, any?]) => {
  const [url, config, options] = Array.isArray(args) ? args : [args];

  if (!url && options) {
    return Promise.resolve(options)
  }

  const res = await axiosInstance.get(url, { ...config });
  // const res = await locInstance.get(url, { ...config });

  return res.data;
};

export const get = async ([url, config]: [string, AxiosRequestConfig?]) => {
  const res = await axiosInstance.get(url, { ...config })
  return res.data
}

export const post = async ([url, config, options]: [string, AxiosRequestConfig, any?]) => {
  if (!url && options) {
    return Promise.resolve(options)
  }

  const res = await axiosInstance.post(url, config?.data || {}, { ...config })
  // const res = await locInstance.post(url, config?.data || {}, { ...config })
  return res.data
}

// ----------------------------------------------------------------------

export const endpoints = {
  table: {
    menu: (tableId: string) => `/api/brand/qr-order/v1/tables/${tableId}/menus`,
    detail: (tableId: string) => `api/brand/qr-order/v1/tables/${tableId}`,
  },
  checkout: {
    prepare: '/api/brand/v2/qrorder/prepare2',
    create: '/api/brand/v2/qrorder/create',
  },
  serviceHelper: {
    requestCall: '/api/brand/v2/service-helper/call',
    current: '/api/brand/v2/service-helper/current',
  },
  payment: (orderParentId: string) => `/api/brand/v2/payment/check?orderParentId=${orderParentId}`,
  order: {
    order: (uuid: string, state: string, from: string, to: string, offset: number = 0, limit: number = 20) =>  `/api/brand/v2/qrorder/list?uuid=${uuid}&status=${state}&from=${from}&to=${to}&offset=${offset}&limit=${limit}`,
    parentMini: (uuid: string,  from: string, to: string) =>  `/api/brand/v2/qrorder/list/mini?uuid=${uuid}&from=${from}&to=${to}`,
    regroup: (uuid: string,  from: string, to: string) =>  `/api/brand/v2/qrorder/regroup?uuid=${uuid}&from=${from}&to=${to}`,
    bill: (id: string) => `/api/brand/v2/qrorder/parent/${id}`,
    detail: (id: string, uuid: string) => `/api/brand/v2/orders/${id}?id=${uuid}`,
    done: () => `/api/brand/v2/qrorder/done`,
  },
  //
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  auth: {
    me: '/api/auth/me',
    login: '/api/auth/login',
    register: '/api/auth/register',
  },
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
  },
  product: {
    list: '/api/product/list',
    details: '/api/product/details',
    search: '/api/product/search',
  },
};
