import { SxProps, Theme } from "@mui/material";
import Button from "@mui/material/Button";
import { MouseEventHandler, ReactNode } from "react";
import style from "src/constants/style";

type Props =  {
  children: ReactNode;
  fullWidth?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  sx?: SxProps<Theme>;
}

export default function ButtonSecond ({ children, onClick, fullWidth = false, sx = {} }: Props) {
  return (
    <Button
      variant="contained"
      onClick={onClick}
      sx={{
        fontFamily: 'Lexend',
        fontSize: 16,
        fontWeight: 700,
        paddingTop: style.CONTAINER_PADDING,
        paddingBottom: style.CONTAINER_PADDING,
        paddingLeft: 3,
        paddingRight: 3,
        background: 'white',
        border: style.BORDER_PRIM,
        color: style.COLOR_APP_PRIM,
        borderRadius: 25,
        width: fullWidth ? 1 : 'auto',
        ...sx,
      }}
    >
      {children}
    </Button>
  )
}
