import { Box, CircularProgress } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { callServicer, getCurrentCallServicer } from "src/apis/service_helper";
import { useUuid } from "src/auth/hooks";
import { ButtonPrim } from "src/components/button";
import Popup from "src/components/popup";
import style from 'src/constants/style';
import { RequestServiceState } from "src/enums/request-service-state";
import { useTable } from "src/hooks/use-table";
import { ICustomer } from "src/types/Order";
import { IServiceHelper } from "src/types/ServiceHelper";
import { ITable } from "src/types/Table";

export default function ButtonCallServicer(/* { customer, table }: { customer: ICustomer, table: ITable | null } */) {
  const { customer } = useUuid()
  const { table, WAIT_INIT_TABLE } = useTable()
  const [data, setData] = useState<IServiceHelper>()
  const [error, setError] = useState<string>()
  const [isOpenPopup, setOpenPopup] = useState(false)

  const getCurrentRequestService = useCallback(async () => {
    if (table === WAIT_INIT_TABLE) return
    if (!table) return
    const response = await getCurrentCallServicer({ table, customer: { uuid: customer.uuid } as ICustomer })
    if (response.error?.message) {
      return;
    }
    setData(response.data)
  }, [table, customer.uuid, WAIT_INIT_TABLE])


  const requestServicer = async () => {
    if (!table) return
    try {
      const response = await callServicer({ table: table!, customer })

      setData(response.data)
    } catch (err) {

      setError(err?.message)
      setOpenPopup(true)
    }

  }


  const isNewRequest = (): boolean => {
    if (!data?.updated) return false;
    const diff = Date.now() - Date.parse(data!.updated)

    return diff < 15000
  }

  const isAcceptRequest = (): boolean => {
    if (!data?.updated) return false;
    const diff = Date.now() - Date.parse(data!.updated)
    return diff < 120000
  }

  useEffect(() => {
    getCurrentRequestService()
    const timer = setInterval(getCurrentRequestService, 15000)
    return () => {
      clearInterval(timer)
    }
  }, [getCurrentRequestService, customer.uuid])

  function isSendSuccess(): boolean {
    return data?.state === RequestServiceState.none && isNewRequest()
  }

  function isAcceptSuccess(): boolean {
    return data?.state === RequestServiceState.accept && isAcceptRequest()
  }

  function getContentButton() {
    if (isSendSuccess()) {
      return (
        <CircularProgress
          size={20}
          thickness={5}

          sx={{
            marginY: 0.5,
            marginX: 2,
            color: style.COLOR_APP_PRIM,
            borderRadius: '50%',
            boxShadow: `inset 0 0 0 3px #00000011 `,

          }}
        />
      )
    }
    if (isAcceptSuccess()) {
      return 'Đã Gọi NV'
    }

    return 'Gọi NV'
  }

  return (
    <Box>
      {/* <Box sx={{ textAlign: 'center', marginTop: '16px' }}>
        {
        
          && 'Bạn đã gửi yêu cầu nhân viên hỗ trợ thành công!'
        }
        {
          data?.state === RequestServiceState.accept && isAcceptRequest()
          && 'Nhân viên đã nhận được yêu cầu và sẽ hỗ trợ quý khách sớm!'
        }
      </Box> */}
      <ButtonPrim
        fullWidth
        disabled={isSendSuccess() || isAcceptSuccess()}
        onClick={requestServicer}
        sx={{ paddingX: 2, paddingY: 0.5 }}

      >
        {
          getContentButton()
        }

      </ButtonPrim>

      <Popup
        title='Thông báo'
        open={isOpenPopup}
        onClose={() => setOpenPopup(false)}
      >
        <Box sx={{ textAlign: 'center', padding: '16px' }}>
          {error}
        </Box>
        <ButtonPrim
          fullWidth
          onClick={() => setOpenPopup(false)}
          sx={{ padding: 1 }}
        >
          Đã nhận
        </ButtonPrim>
      </Popup>
    </Box>

  )
}