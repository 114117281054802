import { lazy, Suspense } from 'react';
// layouts
import MainLayout from 'src/layouts/main';
// import DetailLayout from 'src/layouts/detail';
// components
import { SplashScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

const Table = lazy(() => import('src/pages/table/table'));
const Menu = lazy(() => import('src/pages/table/menu'));
// const Checkout = lazy(() => import('src/pages/table/checkout'));
// const Payment = lazy(() => import('src/pages/table/payment'));

// ----------------------------------------------------------------------

export const tableRoutes = [
  {
    path: '/',
    element: (
      <MainLayout activeTab={1}>
        <Suspense fallback={<SplashScreen />}>
          <Menu />
        </Suspense>
      </MainLayout>
    ),
  },
  {
    path: 'table',
    element: (
      <Table />
    ),
  },
];
