import React, { useState, useEffect } from 'react'
import Dialog from '@mui/material/Dialog'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions/transition'
import { DialogContent, DialogTitle, IconButton, Stack, Typography } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'

const Transition = React.forwardRef((
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
// eslint-disable-next-line react/jsx-no-useless-fragment
) => <Slide direction="up" ref={ref} {...props}>{props.children || <></>}</Slide> )

interface PopupInterface {
  children: JSX.Element | JSX.Element[]
  open: boolean
  onClose?: () => void
  dialogClassName?: string
  defaultClose?: boolean
  title?: string
  height?: string
}
const Popup: React.FC<PopupInterface> = ({
  children,
  open,
  onClose,
  dialogClassName,
  defaultClose,
  title,
  height
}) => {
  const [openDialog, setOpenDialog] = useState(false)

  useEffect(() => {
    setOpenDialog(open)
    return () => setOpenDialog(false)
  }, [open])

  const handleClose = () => {
    if (onClose) {
      onClose()
    }

    setOpenDialog(false)
  }

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={openDialog}
      onClose={handleClose}
      TransitionComponent={Transition}
      PaperProps={{
        style: {
          maxWidth: 550,
          width: '100%',
          margin: 16,
          borderRadius: 16,
          maxHeight: height || '550px'
        },
        className: `scrolling ${dialogClassName}`
      }}
    >
      <DialogTitle sx={{ mt: 2, mx: 1.5, p: 0 }}>
        <Stack direction="row">
          {defaultClose && (
            <IconButton onClick={handleClose} sx={{ p: 0 }}>
              <ClearIcon />
            </IconButton>
          )}
          <Typography
            fontWeight="bold"
            width="100%"
            justifyContent="center"
            display="flex"
            alignItems="center"
          >
            {title}
          </Typography>
        </Stack>
      </DialogTitle>

      <DialogContent sx={{ mb: 2, px: '12px' }}>{children}</DialogContent>
    </Dialog>
  )
}
export default Popup
