import { Box } from "@mui/material"
import { useUuid } from "src/auth/hooks/use-uuid"
import { useTable } from "src/hooks/use-table"
import style from 'src/constants/style'
import { useState } from "react"

import am69Icon from 'src/assets/icons/6-9am.svg'
import am912Icon from 'src/assets/icons/9-12am.svg'
import pm123Icon from 'src/assets/icons/12-3pm.svg'
import pm36Icon from 'src/assets/icons/3-6pm.svg'
import pm69Icon from 'src/assets/icons/6-9pm.svg'
import pm96Icon from 'src/assets/icons/9-6pm.svg'
import editIcon from 'src/assets/icons/edit.svg'
import tableIcon from 'src/assets/icons/table.svg'
import CustomerEdit from './edit'

const greeting = () => {
  const nowHours = new Date().getHours()

  if (nowHours < 6 || nowHours >= 21) {
    return [pm96Icon, 'Xin chào']
  }

  if (nowHours >=6 && nowHours < 9) {
    return [am69Icon, 'Chào buổi sáng']
  }

  if (nowHours >= 9 && nowHours < 12) {
    return [am912Icon, 'Chào buổi sáng']
  }

  if (nowHours >= 12 && nowHours < 15) {
    return [pm123Icon, 'Chào buổi trưa']
  }

  if (nowHours >= 15 && nowHours < 18) {
    return [pm36Icon, 'Chào buổi chiều']
  }

  return [pm69Icon, 'Chào buổi tối']
}

export default function CustomerInfo () {
  const { table } = useTable()
  const { customer, setCustomer } = useUuid()
  const [openEdit, setOpenEdit] = useState(false)

  const [greetingIcon, greetingText] = greeting()

  return (
    <>
      <CustomerEdit
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        customer={customer}
        setCustomer={setCustomer}
      />

      <Box
        display="flex"
        justifyContent='space-between'
        sx={{ paddingBottom: 2, 
          paddingLeft: style.CONTAINER_PADDING,
          paddingRight: style.CONTAINER_PADDING,
        }}
        onClick={() => setOpenEdit(true)}
      >
        <Box display='flex' alignItems='center' flexGrow={1} justifyContent={table ? 'left' : 'center'} width={0.7} paddingRight={1}>
          <img className='vertical-middle mr-0.5' src={greetingIcon} alt="day-icon" width='19px' height='16px' />
          <Box fontSize={12} sx={{ verticalAlign: 'middle', whiteSpace: 'nowrap', maxWidth: 1, overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {greetingText}, <b>{customer.name}</b>
          </Box>
          <img className='w-2 h-2 opacity-40 ml-0.5' src={editIcon} alt="edit-icon" />
        </Box>

        {table && <Box
          display='flex'
          fontSize={16}
          lineHeight='24px'
          sx={{ paddingLeft: 1, paddingRight: 1, borderRadius: 0.5, border: style.BORDER }}
        >
          <img className='vertical-middle mr-0.5' src={tableIcon} alt="table-icon" />
          <b className='vertical-middle'>{table && table.name}</b>
        </Box>}
      </Box>
      </>
  )
}
