// hooks
import { useCallback, useEffect, useMemo } from 'react'
import { useLocalStorage, getStorage } from 'src/hooks/use-local-storage'
import { DAY_INDEX } from 'src/types/OpenTime'
import { ITable } from 'src/types/Table'

const STORAGE_KEY = 'table'
const SESSION_TIME = 3 * 60 * 60 * 1000 // 3 hours

const WAIT_INIT_TABLE: ITable = {
  _id: '_0',
  name: '_0',
  image: '',
  hub: null,
  store: null,
}

const initialState: {session: number, table: ITable | null} = {
  session: 0,
  table: WAIT_INIT_TABLE,
}

export const useTable = () => {
  const { state, update, restore } = useLocalStorage(STORAGE_KEY, initialState)

  const refreshSession = useCallback(
    () => {
      update('session', Date.now())
    },
    [update],
  )

  const setTable = useCallback(
    (table: ITable) => {
      refreshSession()
      update('table', table)
    },
    [refreshSession, update],
  )

  const isValidSession = useCallback(
    (): boolean => {
      if (!state.table) return false
      if (state.session + SESSION_TIME < Date.now()
        && state.table
        && state.table !== WAIT_INIT_TABLE
      ) {
        deleteTable()
        return false
      }
      return true
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.table, state.session]
  )

  const getTable = useCallback(
    (): ITable | null => isValidSession() ? state.table : null,
    [isValidSession, state.table],
  )

  const deleteTable = useCallback(
    () => {
      update('table', null)
      update('session', 0)
    },
    [update]
  )

  const notice = useMemo(
    () => {
      let openTimeNotice = ''
      const table = getTable()

      if (!table || !table.hub) return openTimeNotice

      const now = new Date()
      const hour = now.getUTCHours() > 16 ? now.getUTCHours() - 17 : now.getUTCHours() + 7
      const minute = now.getUTCMinutes()
      const timePoint = hour * 60 + minute
  
      const dateLable = DAY_INDEX[now.getUTCDay()]
      const todayOpenTime = table.hub.openTimes.find(({ day }) => day === dateLable)
  
      let isValidOpenTime = true
      const dislayOpenTimes = []
  
      if (todayOpenTime) {
        isValidOpenTime = false
  
        for (let i = 0; i < todayOpenTime.times.length; i += 1) {
          const [startHour, startMinute] = todayOpenTime.times[i][0].split(':')
          const [endHour, endMinute] = todayOpenTime.times[i][1].split(':')
    
          const startTimePoint = parseInt(startHour, 10) * 60 + parseInt(startMinute, 10)
          const endTimePoint = parseInt(endHour, 10) * 60 + parseInt(endMinute, 10)
    
          if (timePoint < endTimePoint) {
            dislayOpenTimes.push(todayOpenTime.times[i][0])
          }
    
          if (startTimePoint <= timePoint && timePoint <= endTimePoint) {
            isValidOpenTime = true
          }
        }
      }

      if (!isValidOpenTime && dislayOpenTimes.length) {
        openTimeNotice = `Sắp mở cửa: Vui lòng gọi món từ ${dislayOpenTimes[0]}`
      }

      return openTimeNotice
    },
    [getTable]
  )

  useEffect(
    () => {
      const restored = getStorage(STORAGE_KEY)

      if (!restored) {
        deleteTable()
      }
    },
    [deleteTable]
  )

  const restoreTable = useCallback(
    () => {
      restore()
    },
    [restore],
  )

  return {
    WAIT_INIT_TABLE,
    table: getTable(),
    getTable,
    setTable,
    isValidSession,
    refreshSession,
    deleteTable,
    notice,
    restoreTable,
  }
}
