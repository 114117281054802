import { lazy, Suspense } from 'react';
// layouts
import MainLayout from 'src/layouts/main';
// import DetailLayout from 'src/layouts/detail';
// components
import { SplashScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

const Empty = lazy(() => import('src/pages/empty/empty'));
const More = lazy(() => import('src/pages/empty/more'));

// ----------------------------------------------------------------------

export const emptyRoutes = [
  {
    path: '/notable',
    element: (
      <MainLayout noNav>
        <Suspense fallback={<SplashScreen />}>
          <Empty />
        </Suspense>
      </MainLayout>
    ),
  },
  {
    path: '/more',
    element: (
      <MainLayout activeTab={2}>
        <Suspense fallback={<SplashScreen />}>
          <More />
        </Suspense>
      </MainLayout>
    ),
  },
];
