// hooks
import { useCallback, useEffect } from 'react'
import { getStorage, useLocalStorage } from 'src/hooks/use-local-storage'

const STORAGE_KEY = 'uuid'
const DEFAULT_NAME = 'Khách lẻ'

const genUuid = () => new Date().getTime().toString()

const initialState = {
    uuid: genUuid(),
    name: DEFAULT_NAME,
    phone: '',
    email: '',
}

export const useUuid = () => {
  const { state, updateState } = useLocalStorage(STORAGE_KEY, initialState)

  const customer = {
    uuid: state.uuid,
    name: state.name,
    phone: state.phone,
    email: state.email,
  }

  const setCustomer = useCallback(
    ({ name, phone, email }: {name?: string, phone?: string, email?: string}) => {
      updateState({
        name: name || DEFAULT_NAME,
        phone: phone || '',
        email: email || '',
      })
    },
    [updateState],
  )

  useEffect(
    () => {
      const restored = getStorage(STORAGE_KEY)

      if (!restored) {
        setCustomer(initialState)
      }
    },
    [setCustomer],
  )

  return {
    uuid: state.uuid,
    customer,
    setCustomer,
  }
}
