import React, { useState } from 'react'
import { styled } from '@mui/system'
import { Box } from '@mui/material'
import {ReactComponent as TickIcon} from 'src/assets/icons/tick.svg'

interface PayMethod {
  name: string
  selected: boolean
  icon?: string
  title?: string
  id: string
}
interface props {
  payMethods: PayMethod[]
  select: (value: string) => void
}
const ButtonGroup: React.FC<props> = ({ payMethods, select }) => {
  const [data, setData] = useState<PayMethod[]>(payMethods)

  const handleClick = (index: number) => {
    const d = [...payMethods]
    d.forEach((item: PayMethod) => {item.selected = false})
    d[index].selected = true
    setData(d)
    select(d[index].id)
  }

  return (
    <Wrap>
      {data.map((item: PayMethod, index: number) => (
        <Btn
          key={index}
          onClick={() => handleClick(index)}
          style={{
            boxShadow: item.selected ? '0px 0px 0px 1px #f04f23 inset' : 'unset'
          }}
        >
          { item.icon && <img src={item.icon} alt={item.name} />}
          <Box sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            textAlign: item.icon ? 'left' : 'center',
            marginLeft: 1,
          }}>
            <Box sx={{ fontWeight: 600 }}>
              {item.title}
            </Box>
            {item.name}
          </Box>
          {item.selected && (
            <Mark>
              {/* <Box sx={{
                width: '12px',
                height: '12px',
                background: `url('${TickIcon}')`,
              }} /> */}
              <TickIcon />
            </Mark>
          )}
        </Btn>
      ))}
    </Wrap>
  )
}
const Wrap = styled('div')({
  display: 'flex',
  gap: '6px',
})

const Mark = styled('div')({
  width: 0,
  height: 0,
  borderStyle: 'solid',
  borderWidth: '0 32px 32px 0',
  borderColor: 'transparent #f04f23 transparent transparent',
  position: 'absolute',
  right: 0,
  top: 0,
  '& > svg': {
    color: 'white',
    width: 12,
    height: 12,
    marginLeft: 18,
    marginTop: 4
  }
})

const Btn = styled('div')({
  height: 48,
  padding: '12px',
  background: '#FCFCFC',
  width: '50%',
  borderRadius: 8,
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontSize: 12,
  position: 'relative',
  overflow: 'hidden',
  flexShrink: 1,
  textAlign: 'center'
})

export default ButtonGroup
