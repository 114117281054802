/* eslint-disable import/no-cycle */
import useSWR from 'swr';
import { useMemo } from 'react';
// utils
import { post, endpoints } from 'src/utils/axios';
import { useTable } from 'src/hooks/use-table'
import { useCheckoutContext } from 'src/sections/checkout/context';
import { IOrder } from 'src/types/Order';
import { ITable } from 'src/types/Table';
import { IItem } from 'src/types/Item';
import { ICoupon } from 'src/types/Coupon';
// types

// ----------------------------------------------------------------------
type Response = {
  coupon: string;
  coupon_applied: boolean;
  couponData: ICoupon;
  finalPrice: number;
  needShip: boolean;
  orders: IOrder[];
  totalItemPrice: number;
  totalItemPromotionPrice: number;
  totalPrice: number
  totalShippingFee: number;
  totalShippingFeeDisplay: number;
  error: {message: string};
}
const nullAxiosConfig = {}
const expiredSessionError = {
  message: 'Phiên đặt món đã hết hạn, vui lòng quét lại qr code để thực hiện đặt món',
}

export function usePrepare(coupon: string) {
  const { items } = useCheckoutContext()
  const { table, WAIT_INIT_TABLE } = useTable()

  const URL: any = useMemo(
    () => {
      const reqData = {
        items,
        hubId: table?.hub?._id || null,
        storeId: table?.store?._id || null,
        tableId: table?._id,
        coupon: coupon || '',
      }

      let urlConfig = [
        '',
        nullAxiosConfig,
        { error: expiredSessionError },
      ]

      if (table) {
        if (table !== WAIT_INIT_TABLE) {
          urlConfig = [endpoints.checkout.prepare, { method: 'POST', data: reqData }]
        } else {
          urlConfig = ['', nullAxiosConfig, { data: {} }]
        }
      }

      return urlConfig
    },
    [WAIT_INIT_TABLE, coupon, items, table],
  )

  const { data, isLoading, error } = useSWR<Response>(URL, post)

  const memoizedValue = useMemo(
    () => ({
      data: data?.error ? undefined : data,
      isLoading,
      error: error || (data?.error ? data : undefined),
    }),
    [data, isLoading, error],
  )

  return memoizedValue
}

export function prepare (items: IItem[], table: ITable | null, WAIT_INIT_TABLE: ITable, coupon?: string) {
  const reqData = {
    items,
    hubId: table?.hub?._id || null,
    storeId: table?.store?._id || null,
    tableId: table?._id,
    coupon: coupon || '',
  }

  let URL: any = [
    '',
    nullAxiosConfig,
    { error: expiredSessionError },
  ]

  if (table) {
    if (table !== WAIT_INIT_TABLE) {
      URL = [endpoints.checkout.prepare, { method: 'POST', data: reqData }]
    } else {
      URL = ['', nullAxiosConfig, { data: {} }]
    }
  }

  return post(URL).then((data: Response) => {
    if (data.error) return Promise.reject(Error(data.error.message))
    return data
  })
}

type CreateOrderProps = {
  items: IItem[];
  table: ITable;
  notes: Record<string, string>;
  paymentMethod: string;
  coupon?: string;
  customer: {
    uuid: string;
    name: string;
    phone: string;
    email: string;
  }
}

export function createOrder ({ items, table, notes, customer, paymentMethod, coupon = '' }: CreateOrderProps) {
  const reqData = {
    items,
    hubId: table.hub?._id || null,
    storeId: table.store?._id || null,
    tableId: table._id,
    customer,
    paymentMethod,
    notes,
    coupon,
  }
  const URL: [string, any] = [
    endpoints.checkout.create,
    { method: 'POST', data: reqData },
  ]

  return post(URL).then((data: { data: any; error: { message: string } }) => {
    if (data.error) return Promise.reject(Error(data.error.message))
    return data
  })
}
