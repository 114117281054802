import { Box, TextField, styled } from "@mui/material"
import { ChangeEvent } from "react";
import AirDrawer from "../drawer"
import { ButtonPrim } from "../button";

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#A0AAB4',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#B2BAC2',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#E0E3E7',
    },
    '&:hover fieldset': {
      borderColor: '#B2BAC2',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#6F7E8C',
    },
  },
});

type Props = {
  open?: boolean;
  onClose?: () => any;
  customer: {name: string, phone: string, email: string};
  setCustomer: ({name, phone, email}: {name: string, phone: string, email: string}) => void;
}

export default function CustomerEdit ({ open, onClose, customer, setCustomer }: Props) {
  const onChange = (field: 'name' | 'phone' | 'email') => (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    customer[field] = e.target.value
    setCustomer({ ...customer })
  }

  return (
    <AirDrawer open={open} onClose={onClose}>
      <Box sx={{ padding: '16px 12px' }}>
        <Box sx={{ fontSize: 16, fontWeight: 600, textAlign: 'center' }}>Cập nhật thông tin</Box>
        <Box sx={{ mt: 2, mb: 2 }}>Vui lòng cung cấp Tên của Quý khách để được phục vụ nhanh chóng & chính xác hơn!</Box>
        <Box>
          <CssTextField
            fullWidth
            label="Họ & Tên"
            sx={{ mb: 2 }}
            value={customer.name}
            onChange={onChange('name')}
          />

          <CssTextField
            fullWidth
            label="Số điện thoại"
            sx={{ mb: 2 }}
            value={customer.phone}
            onChange={onChange('phone')}
          />

          <CssTextField
            fullWidth
            label="Email"
            sx={{ mb: 2 }}
            value={customer.email}
            onChange={onChange('email')}
          />

          <ButtonPrim fullWidth onClick={onClose}>
            Xong
          </ButtonPrim>
        </Box>
      </Box>
    </AirDrawer>
  )
}
