import { useMemo } from "react"
import { createSearchParams } from "react-router-dom"
import { useUuid } from "src/auth/hooks/use-uuid"
import { useTable } from "src/hooks/use-table"
import { IServiceHelper } from "src/types/ServiceHelper"
import { endpoints, fetcher, post } from "src/utils/axios"
import useSWR from "swr"

type Response = {
  data: IServiceHelper;
  error?: { message?: string };
}

export function getCurrentCallServicer(data: IServiceHelper): Promise<Response> {

  const search = createSearchParams({
    customerId: data.customer?.uuid ?? '',
    tableId: data?.table?._id ?? ''
  })
  const URL = `${endpoints.serviceHelper.current}?${search}`

  return fetcher(URL)

}

export function callServicer(data: IServiceHelper): Promise<Response> {

  const URL = `${endpoints.serviceHelper.requestCall}`
  const body = {
    customer: data.customer,
    tableId: data.table?._id
  }
  return post([URL, { data: body }])

}
