import { Box } from "@mui/material"
import { useUuid } from "src/auth/hooks/use-uuid"
import { useTable } from "src/hooks/use-table"

import editIcon from 'src/assets/icons/edit.svg'
import tableIcon from 'src/assets/icons/table.svg'
import userSquareIcon from 'src/assets/icons/user-square.svg'
import style from 'src/constants/style'
import { useState } from "react"
import CustomerEdit from './edit'

export default function CustomerSimple () {
  const { table } = useTable()
  const { customer, setCustomer } = useUuid()
  const [openEdit, setOpenEdit] = useState(false)

  return (
    <>
      <CustomerEdit
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        customer={customer}
        setCustomer={setCustomer}
      />

      <Box
        display="flex"
        justifyContent='space-between'
      >
        <Box
          display='flex'
          alignItems='center'
          flexGrow={1}
          justifyContent={table ? 'left' : 'center'}
          width={0.7}
          paddingRight={1}
          onClick={() => setOpenEdit(true)}
        >
          <img className='vertical-middle mr-0.5' src={userSquareIcon} alt="day-icon" />
          <Box fontSize={12} sx={{ verticalAlign: 'middle', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: 1, overflow: 'hidden' }}>
            {customer.name} {customer.phone ? `(${customer.phone})` : ''}
          </Box>
          <img className='w-2 h-2 opacity-40 ml-0.5' src={editIcon} alt="edit-icon" />
        </Box>

        {table && <Box
          display='flex'
          fontSize={16}
          lineHeight='24px'
          sx={{ paddingLeft: 1, paddingRight: 1, borderRadius: 0.5, border: style.BORDER }}
        >
          <img className='vertical-middle mr-0.5' src={tableIcon} alt="table-icon" />
          <b className='vertical-middle'>{table && table.name}</b>
        </Box>}
      </Box>
    </>
  )
}
