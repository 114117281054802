// @mui
import Box from '@mui/material/Box';
// routes
import { usePathname } from 'src/routes/hooks';
//
// import Footer from './footer';
import style from 'src/constants/style'
import Container from '@mui/material/Container';
import { useTable } from 'src/hooks/use-table';
import Header from './header';
import Nav from './nav';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
  noPadding?: boolean;
  noNav?: boolean;
  activeTab?: number;
};

export default function MainLayout({ children, noPadding, noNav = false, activeTab = 0 }: Props) {
  const pathname = usePathname();
  const { notice } = useTable()

  return (
    <Box
      maxWidth={style.MAX_WIDTH}
      margin="auto"
      display='flex'
      flexDirection='column'
      height={1}
      sx={{ background: style.BG_COLOR, fontSize: '14px', fontFamily: 'Lexend' }}
    >
      <Header />

      <Box
        component="main"
        sx={{
          marginTop: notice ? 12 : 7,
          pt: noPadding ? 0 : 2,
          flexGrow: 1,
          background: style.BG_COLOR,
        }}
      >
        <Container sx={{ height: 1, padding: 0 }}>
          {children}
        </Container>
      </Box>

      {!noNav && <Nav activeTab={activeTab} />}

      {/* <Footer /> */}
    </Box>
  );
}
